import React from 'react'
import s from './Footer.module.scss'
import { Link } from 'react-router-dom'
import ButtonLink from '../Button_link/Button_link'
import { ReactComponent as WhatsAppIcon } from './../../assets/icon/whatsapp_icon.svg'
import { ReactComponent as InstagramIcon } from './../../assets/icon/instagram_icon.svg'
import { ReactComponent as MailIcon } from './../../assets/icon/mail_icon.svg'
import { ReactComponent as TelegramIcon } from './../../assets/icon/telegram_icon.svg'
import { ReactComponent as LocationIcon } from './../../assets/icon/location_icon.svg'
import { ReactComponent as PhoneIcon } from './../../assets/icon/phone_icon.svg'
import { ReactComponent as MailIconGold } from './../../assets/icon/mail_icon_gold.svg'
import footer_logo from '../../assets/images/footer_logo.png'
import { useAppSelector } from '../../store/hooks'

const Footer = () => {
    const { info } = useAppSelector(state => state.info)
    const { countInWhishlist } = useAppSelector(state => state.whishlist)


    return (
        <footer>
            <div className={s.container}>
                <div className={s.wrapper}>
                    <div className={s.card}>
                        <Link className={s.logo} to='/'>
                            <img src={footer_logo} alt="footer logo" />
                        </Link>
                        <ButtonLink link={'/pre-order'}>Узнайте стоимость заказа<br />
                            БЕСПЛАТНО ЗА 1 МИНУТУ
                        </ButtonLink>
                        <ul className={s.sosial_icon}>
                            <li><a target='_blank' rel="noreferrer noopener" href={`https://wa.me/${info?.contacts.whatsapp}`}><WhatsAppIcon /></a></li>
                            <li><a target='_blank' rel="noreferrer noopener" href={info?.contacts.instagram}><InstagramIcon /></a></li>
                            <li><a target='_blank' rel="noreferrer noopener" href={`https://t.me/${info?.contacts.telegram}`}><TelegramIcon /></a></li>
                            <li><a target='_blank' rel="noreferrer noopener" href={`mailto:${info?.contacts.email}`}><MailIcon /></a></li>
                        </ul>
                    </div>
                    <div className={s.card}>
                        <ul>
                            <li>
                                <i><LocationIcon /></i>
                                <div>
                                    <p>Адрес</p>
                                    <a target='_blank' rel="noreferrer noopener" href={`https://2gis.kg/bishkek/search/${info?.contacts.address}`}>{info?.contacts.address}</a>
                                </div>
                            </li>
                            <li>
                                <i><PhoneIcon /></i>
                                <div>
                                    <p>Телефон</p>
                                    <a href={`tel:${info?.contacts.phone}`}>{info?.contacts.phone}</a>
                                </div>
                            </li>
                            <li>
                                <i><MailIconGold /></i>
                                <div>
                                    <p>Почта</p>
                                    <a href={`mailto:${info?.contacts.email}`}>{info?.contacts.email}</a>
                                </div>
                            </li>
                        </ul>
                        <ul className={s.sosial_icon}>
                            <li><a target='_blank' rel="noreferrer noopener" href={`https://wa.me/${info?.contacts.whatsapp}`}><WhatsAppIcon /></a></li>
                            <li><a target='_blank' rel="noreferrer noopener" href={info?.contacts.instagram}><InstagramIcon /></a></li>
                            <li><a target='_blank' rel="noreferrer noopener" href={`mailto:${info?.contacts.email}`}><MailIcon /></a></li>
                            <li><a target='_blank' rel="noreferrer noopener" href={`https://t.me/${info?.contacts.telegram}`}><TelegramIcon /></a></li>
                        </ul>
                    </div>
                    <div className={s.card}>
                        <p>Меню</p>
                        <div >
                            <ul>
                                <li><Link to={'/'}>Главная</Link></li>
                                <li><Link to={'/catalog'}>Каталог</Link></li>
                                <li><Link to={'/about-us'}>О нас</Link></li>
                                <li><Link to={'/reviews'}>Отзывы</Link></li>
                            </ul>
                            <ul>
                                <li><Link data-count={countInWhishlist} className={s.whish} to={'/favorites'}>Избранное</Link></li>
                                <li><Link to={'/contacts'}>Контакты</Link></li>
                                <li><Link className={s.different_style} to={'/pre-order'}>Бесплатная<br />консультация</Link></li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div className={s.box_copyright}>
                    <Link to={'/privacy-policy'}>Политика конфиденциальности</Link>
                    <p>Bioro 2024. Все права защищены</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer