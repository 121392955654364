import React, { FC } from 'react';
import s from './AboutIUs.module.scss';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';
import { ReactComponent as Long_arrow } from '../../../assets/icon/long_arrow.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/icon/instagram_icon.svg';
import { Interweave } from 'interweave';

const AboutUS: FC = () => {
    const { info } = useAppSelector(state => state.info);

    const extractInstagramUsername = (url: string) => {
        const regex = /https:\/\/www\.instagram\.com\/([^/?#]+)/;
        const match = url.match(regex);
        return match ? match[1] : '';
    };

    const instagramUsername = info?.contacts.instagram ? extractInstagramUsername(info.contacts.instagram) : '';

    return (
        <section>
            <div className={`${s.wrapper} container`}>
                <div className={s.content_img}>
                    <div>
                        <img src={info?.about_us_image_1} alt={info?.about_us_image_1} />
                    </div>
                </div>
                <div className={s.content_title}>
                    <div>
                        <h2 className='title'>{info?.about_us_title}</h2>
                        <article>
                            <Interweave content={info?.about_us_description} />
                        </article>
                        <a className={s.button_insta} target='_blank' rel="noreferrer noopener" href={info?.contacts.instagram}>
                            <InstagramIcon />
                            @{instagramUsername || 'Instagram'}
                        </a>
                        <Link to={'/contacts'} className={`${s.button} button`}>
                            БЕСПЛАТНАЯ КОНСУЛЬТАЦИЯ <Long_arrow />
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutUS;
