import React, { FC, useEffect } from 'react';
import s from './Reviews.module.scss'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Loader from '../../components/Loader/Loader';
import { Helmet } from 'react-helmet-async';
import { Interweave } from 'interweave';
import { fetchByReviews } from '../../store/slices/infoSlice';

const Reviews: FC = () => {
    const { reviews, loading } = useAppSelector(state => state.info)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchByReviews())
    }, [dispatch])

    useEffect(() => {
        window.scrollTo({
            behavior: 'smooth',
            top: 0
        })
    }, [])


    return (
        <section className={s.reviews}>
            <Helmet>
                <meta property="og:title" content={`Отзывы | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro`} />
                <meta name="twitter:title" content={`Отзывы | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro`} />
                <link rel="canonical" href={`https://biorokg.com/reviews`} />
                <title>
                    Отзывы | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro
                </title>
            </Helmet>
            {loading && <Loader />}
            <div className={s.bg_title}>
                <h1 className={s.title}>Отзывы</h1>
            </div>
            <div className={s.line}></div>
            <div className={`${s.reviews_wrapper} container`}>
                {
                    reviews?.map(e => (
                        <div className={s.card} key={e.id}>
                            <div className={s.box_img}>
                                <img src={`${process.env.REACT_APP_BACKEND_URL}/storage/${e.image}`} alt={`${e.name}`} />
                            </div>
                            <div className={s.content}>
                                <div className={s.name_date_wrapper}>
                                    <h2>{e.name}</h2>
                                    <span className={s.date}>{e.date}</span>
                                </div>
                                <Interweave className={s.text} content={e?.description} />
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className={s.line}></div>
        </section>
    );
};

export default Reviews;