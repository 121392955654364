import React from 'react';
import s from './Header.module.scss'
import { Link, NavLink } from 'react-router-dom';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import logo from '../../assets/images/BIORO.png'
import { useAppSelector } from '../../store/hooks';

const Header = () => {
    const { countInWhishlist } = useAppSelector(state => state.whishlist)
    return (
        <header>
            <div className={`${s.wrapper} container`}>
                <BurgerMenu />
                <nav>
                    <ul className={s.menu}>
                        <li><NavLink to="/about-us" className={({ isActive }) => isActive ? s.active : ''}> О нас</NavLink></li>
                        <li><NavLink to={'/reviews'} className={({ isActive }) => isActive ? s.active : ''}>Отзывы</NavLink></li>
                        <li><NavLink to={'/catalog'} className={({ isActive }) => isActive ? s.active : ''}>Каталог</NavLink></li>
                        <li><NavLink to={'/favorites'}
                            data-count={countInWhishlist}
                            className={({ isActive }) => isActive ? `${s.active} ${s.whish}` : s.whish}>Избранное</NavLink></li>
                    </ul>
                    <Link className={s.logo} to='/'>
                        <img src={logo} alt="logo" />
                        <p>
                            швейное производство №1
                        </p>
                    </Link>
                </nav>
                <ul className={s.menu}>
                    <li><NavLink
                        to={'/pre-order'}
                        className={({ isActive }) => `${isActive ? s.active : ''} ${s.anotherStyle}`}>
                        Узнайте стоимость заказа
                        <br />
                        БЕСПЛАТНО ЗА 1 МИНУТУ
                    </NavLink>
                    </li>
                    <li><NavLink to={'/contacts'} className={({ isActive }) => isActive ? s.active : ''}>Контакты</NavLink></li>
                </ul>
            </div>
        </header>
    );
};

export default Header;